
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'About',
  components: {
  },
  methods: {
    goToInstagram(){
      window.open('https://www.instagram.com/carteconfituur.be/', '_blank');
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    let projectLink = document.getElementById('projectLink');
    projectLink!.classList.remove('router-link-active','router-link-exact-active');
  }
});
